<template>
  <div class="console-window">
    <div style="width: 100%; height:40px; background-color: white; color: black;">
      Terminal
    </div>
    <div class="console-content" ref="consoleContent">
      <div v-for="item in msgShown" :key="item.id" style="display: flex;   text-align: left;">
        <div style="flex-shrink: 0; width: 30px;">$</div>
        <div :style="{ color: item.text===selectedOption? 'blue':item.color }" style="  text-align: left;">{{ item.text }}</div>
      </div>
      <div style="display: flex;">
        <div>$ &nbsp;</div>
        <div autofocus class="input" contenteditable="true"></div>
      </div>
    </div>
    <ins class="adsbygoogle"
     style="display:block"
     data-ad-client="ca-pub-6771929648020101"
     data-ad-slot="4578207820"
     data-ad-format="auto"
     data-full-width-responsive="true"></ins>
  </div>
</template>

<script setup>
import { ref, onUpdated, onMounted, nextTick } from 'vue'

onMounted(() => {
  const script = document.createElement('script');
  script.async = true;
  script.src = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6771929648020101";
  script.crossOrigin = "anonymous";
  document.head.appendChild(script);
  script.onload = () => {
    window.adsbygoogle = window.adsbygoogle || [];
    window.adsbygoogle.push({});
  };
});


const msgShown = ref([]);
const options = ref([]);
const selectedOption = ref('');
const isListening = ref(false);
const consoleContent = ref(null);

function scrollToBottom() {
  nextTick(() => {
    if (consoleContent.value) {
      consoleContent.value.scrollTop = consoleContent.value.scrollHeight;
    }
  });
}

async function addText(text, type = 'text', color = 'white', delayBetweenChars = 50, delayAfterLine = 300) {
  const lines = text.split('\n'); // 分割文本为多行
  for (let line of lines) {
    const startIndex = msgShown.value.length;
    let displayText = '';

    for (let char of line) {
      await new Promise(resolve => setTimeout(resolve, delayBetweenChars));
      displayText += char;
      msgShown.value[startIndex] = { id: startIndex, text: displayText, color: color, type: type };
    }

    await new Promise(resolve => setTimeout(resolve, delayAfterLine));

    if (type === 'option') {
      options.value.push(line);
      if (options.value.length === 1) {
        selectedOption.value = line; // 默认选中第一个选项
      }
    }
    scrollToBottom();
  }
}

async function main() {
  await addText('Welcome to my page!');
  await addText('My name is senyangcai.', 'text', 'green');
  await addText('I am a software engineer.', 'text', 'green');
  await addText('Please select an option to view more information:', 'text', 'green');
  await addText('study experience', 'option', 'white');
  await addText('work experience', 'option', 'white');
  await addText('projects', 'option', 'white');
  await addText('skills', 'option', 'white');
  await addText('contact', 'option', 'white');
  isListening.value = true;
}

main();

const information = {
  'study experience': 'University of Jiangsu Province \n bachelor of software engineering \n from 2019 to 2023',
  'work experience': 'IGG \n from 2021.7 to 2021.9. \n intern developer and tester. \n Build an ability test system for the company. \n \n APP Group \n from 2023.4 to 2023.7. \n SAP ABAP developer. \n ABAP Transaction Program Development. \n \n Wicresoft \n from 2023.7 to 2024.6. \n Azure SDK developer and tester. \n Cloud SDK Development, AI repostories development. \n \n Huachi Tech \n from 2024.6 to now. \n Software Engineer. \n Build AI workflow for anime production. \n',
  'projects': 'blockchain based transaction system \n a safe and anonymous chat system \n \n Huachi AI image generate website \n a high performance image generate website',
  'skills': 'I am proficient in Python, go, .NET and Vue. \n Proficient in distributed systems, Docker, front-end and back-end development, high concurrency and high-performance systems, AI application development, etc.',
  'contact': 'e-mail: wdyg666@gmail.com  github:https://github.com/dotnet-7 \n feel free to contact me!'
}

window.addEventListener('keydown', async(event) => {
  if (!isListening.value) return;

  if (event.key === 'ArrowUp') {
    const index = options.value.indexOf(selectedOption.value);
    if (index > 0) {
      selectedOption.value = options.value[index - 1];
    }
  } else if (event.key === 'ArrowDown') {
    const index = options.value.indexOf(selectedOption.value);
    if (index < options.value.length - 1) {
      selectedOption.value = options.value[index + 1];
    }
  }
  if (event.key === 'Enter') {
    const text = selectedOption.value;
    await addText(information[text], 'text', 'white');
    isListening.value = false;
    options.value = [];
    await addText('Please select an option to view more information:', 'text', 'green');
    await addText('study experience', 'option', 'white');
    await addText('work experience', 'option', 'white');
    await addText('projects', 'option', 'white');
    await addText('skills', 'option', 'white');
    await addText('contact', 'option', 'white');
    isListening.value = true;
  }

});
</script>

<style>
#app {
  width: 100%;
  height: 100vh;
}

html, body, #app {
  height: 80vh;
  margin: 0;
  padding: 1%;
  font-family: Consolas;
  font-size: 25px;
  background-color: #000000;
  box-sizing: border-box;
}

.console-content {
  position: relative;
  font-family: Consolas;
  overflow-y: auto;
  height: calc(100% - 40px);

}

.console-window {
  position: relative;
  width: 500px;
  height: 50%;
  background-color: #000000;
  color: white;
  padding: 0;
  box-sizing: border-box;
  font-family: Consolas;
  border: 1px solid white;
  box-shadow: 1 1 10px rgba(148, 148, 148, 0.5);
}

.input {
  background-color: transparent;
  border: none;
  overflow: hidden;
  resize: none;
  color: white;
  font-family: Consolas;
  font-size: 25px;
  outline: none;
  display: flex;
  min-width: 150px;
  align-items: center;
}
</style>
